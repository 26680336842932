import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "", "justify-space-between": "" } },
        [
          _c(VFlex, { attrs: { xs9: "" } }, [_c("view-title")], 1),
          _c(
            "div",
            { staticClass: "table-length-row" },
            [
              _c(
                VFlex,
                { attrs: { md5: "", xs5: "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      label: "Search Users",
                      "single-line": "",
                      solo: "",
                      "prepend-inner-icon": "mdi-magnify",
                      clearable: ""
                    },
                    model: {
                      value: _vm.filter,
                      callback: function($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "button-wrapper" }, [
                _c(
                  "div",
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "add-user-button button-radius",
                        attrs: { type: "submit", color: "primary" },
                        on: { click: _vm.addNewUser }
                      },
                      [
                        _c(VIcon, { attrs: { left: "", dark: "" } }, [
                          _vm._v("mdi-plus")
                        ]),
                        _vm._v("ADD NEW USER\n          ")
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "button-radius",
                        attrs: { color: "primary" }
                      },
                      [
                        _c(
                          "download-csv",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.users,
                                expression: "users"
                              }
                            ],
                            attrs: {
                              data: _vm.formatUsersForReports("Site Manager"),
                              name: "site-operators.csv"
                            }
                          },
                          [
                            _c(VIcon, { attrs: { left: "", dark: "" } }, [
                              _vm._v("mdi-download")
                            ]),
                            _vm._v("export operators\n            ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(
                VDataTable,
                {
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.users,
                    search: _vm.filter,
                    pagination: _vm.pagination
                  },
                  on: {
                    "update:pagination": function($event) {
                      _vm.pagination = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "items",
                      fn: function(props) {
                        return [
                          _c("tr", { staticStyle: { cursor: "pointer" } }, [
                            _c(
                              "td",
                              {
                                staticStyle: { "max-width": "80px !important" },
                                on: {
                                  click: function($event) {
                                    return _vm.goToUser(props.item)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "User-Role-Badge",
                                    style:
                                      "background-color:" +
                                      props.item.badgeColour
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(props.item.initials) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "text-xs-left",
                                staticStyle: {
                                  "max-width": "250px !important"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.goToUser(props.item)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(props.item.name) +
                                    "\n            "
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "text-xs-left",
                                staticStyle: {
                                  "max-width": "250px !important"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.goToUser(props.item)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(props.item.role) +
                                    "\n            "
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "text-xs-left",
                                staticStyle: {
                                  "max-width": "250px !important"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.goToUser(props.item)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "overflow-ellipsis" },
                                  [_vm._v(_vm._s(props.item.info.email))]
                                ),
                                _c("div", [
                                  _vm._v(_vm._s(props.item.info.phone))
                                ])
                              ]
                            ),
                            _c(
                              "td",
                              [
                                _c("toggle", {
                                  attrs: {
                                    type: "Blue-red",
                                    leftText: "Active",
                                    leftValue: true,
                                    rightText: "Inactive",
                                    rightValue: false,
                                    onClickHandler: _vm.setUserStatus(
                                      props.item.id
                                    ),
                                    position: "start",
                                    stylingClass: "noclass"
                                  },
                                  model: {
                                    value: props.item.status,
                                    callback: function($$v) {
                                      _vm.$set(props.item, "status", $$v)
                                    },
                                    expression: "props.item.status"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(VProgressLinear, {
                    attrs: { color: "blue", indeterminate: "", height: "7" },
                    scopedSlots: _vm._u([
                      {
                        key: "progress",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }